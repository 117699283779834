import React from 'react'
import LoadingBar from 'react-redux-loading-bar'
import { NotificationContainer } from 'react-notifications'

// Register Component
import Register from '../components/Auth/Register'

const RegisterIndex = ({location, pageContext}) => {
    return (
        <>
            <NotificationContainer />
            <LoadingBar scope="sectionBar" />
            <Register location={location} />
        </>
    );
}

export default RegisterIndex